import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faFacebookSquare, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="flex flex-col h-auto">
      <div className="bg-darkBlue py-2 text-gray-400 flex-grow"> {/* Ajustado para bg-darkBlue */}
        <div className="container px-4 mx-auto">
          <div className="-mx-4 flex flex-wrap justify-between">
            <div className="px-4 my-2 w-full xl:w-1/5">
              <a href="/" className="block w-56 mb-8">
                <img src="/logoFP_Branca.png" alt="FP Automação" className="w-40" />
              </a>
              <p className="text-left text-white"> {/* Ajuste para garantir legibilidade */}
                Especializados em automação industrial e soluções tecnológicas.
              </p>
            </div>

            <div className="px-4 my-2 w-full sm:w-auto">
              <div>
                <h2 className="inline-block text-2xl pb-2 mb-2 border-b-4 border-lightGreen">Empresa</h2>
              </div>
              <ul className="leading-7 text-white"> {/* Ajuste para garantir legibilidade */}
                <li><a href="#" className="hover:text-lightGreen">Sobre Nós</a></li>
                <li><a href="#" className="hover:text-lightGreen">Termos & Condições</a></li>
                <li><a href="#" className="hover:text-lightGreen">Política de Privacidade</a></li>
                <li><a href="#" className="hover:text-lightGreen">Contato</a></li>
              </ul>
            </div>

            <div className="px-4 my-2 w-full sm:w-auto">
              <div>
                <h2 className="inline-block text-2xl pb-2 mb-2 border-b-4 border-lightGreen">Conecte-se Conosco</h2>
              </div>
              <div className="flex space-x-4">
                <a href="#" aria-label="LinkedIn" className="inline-flex items-center justify-center h-8 w-8 border border-gray-100 rounded-full hover:text-lightGreen hover:border-lightGreen">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="#" aria-label="Facebook" className="inline-flex items-center justify-center h-8 w-8 border border-gray-100 rounded-full hover:text-lightGreen hover:border-lightGreen">
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
                <a href="#" aria-label="Instagram" className="inline-flex items-center justify-center h-8 w-8 border border-gray-100 rounded-full hover:text-lightGreen hover:border-lightGreen">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="#" aria-label="X" className="inline-flex items-center justify-center h-8 w-8 border border-gray-100 rounded-full hover:text-lightGreen hover:border-lightGreen">
                  <FontAwesomeIcon icon={faXTwitter} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lightGreen py-1 text-gray-100">
        <div className="container mx-auto px-1">
          <div className="-mx-1 flex flex-wrap justify-center text-center">
            <div className="px-1 w-full">
              Copyright © 2020
              <script>new Date().getFullYear()2020 && document.write("- " + new Date().getFullYear())</script>- 2024
              FP Automação. Todos os Direitos Reservados.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faFacebookSquare, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Popover } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/20/solid';

function Header() {
  return (
    <header className="relative">
      {/* Barra verde superior */}
      <div className="h-2 lg:h-3 w-full bg-lightGreen"></div>

      {/* Barra azul para telas grandes */}
      <div className="absolute top-0 right-0 hidden lg:flex h-9 z-10 bg-darkBlue text-white"
        style={{ clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 3rem 100%)', width: '60%' }}>
        <div className="flex justify-around w-full px-4 ml-5 whitespace-nowrap">
          <div className="flex items-center space-x-20 text-base font-medium">
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon icon={faPhoneAlt} />
              <a href="tel:+551159902135" className="text-white ml-1">+ (11) 5990-2135</a>
            </div>
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon icon={faClock} />
              <span className="text-white">Seg - Sáb : 9:00 - 18:00</span>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <a href="#" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a href="#" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookSquare} /></a>
            <a href="#" aria-label="X"><FontAwesomeIcon icon={faXTwitter} /></a>
            <a href="#" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
          </div>
        </div>
      </div>

      {/* Barra azul para telas médias */}
      <div className="relative hidden md:flex lg:hidden h-9 z-10 w-full bg-darkBlue text-white">
        <div className="flex justify-around w-full px-8 whitespace-nowrap">
          <div className="flex items-center space-x-4 text-base font-medium">
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon icon={faPhoneAlt} />
              <a href="tel:+551159902135" className="text-white ml-1">+ (11) 5990-2135</a>
            </div>
            <div className="flex items-center space-x-1">
              <FontAwesomeIcon icon={faClock} />
              <span className="text-white">Seg - Sáb : 9:00 - 18:00</span>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <a href="#" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a href="#" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookSquare} /></a>
            <a href="#" aria-label="X"><FontAwesomeIcon icon={faXTwitter} /></a>
            <a href="#" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
          </div>
        </div>
      </div>

      {/* Menu responsivo para telas pequenas */}
      <div className="relative bg-white p-6 flex justify-between items-center lg:mt-2">
        <div className="ml-4 z-20 lg:mt-0 sm:ml-12 md:ml-24 lg:ml-36">
          <img 
            src="/logoFP.png" 
            alt="Logo da Empresa" 
            className="h-auto max-h-24 w-auto" 
            style={{ maxHeight: '6rem' }} 
          />
        </div>
        <nav className="hidden lg:flex space-x-16 text-xl mr-4 font-bold">
          <a href="#" className="text-darkBlue hover:text-lightGreen">Início</a>
          <a href="#" className="text-darkBlue hover:text-lightGreen">Serviços</a>
          <a href="#" className="text-darkBlue hover:text-lightGreen">Projetos</a>
          <a href="#" className="text-darkBlue hover:text-lightGreen">Blog</a>
          <a href="#" className="text-darkBlue hover:text-lightGreen">Páginas</a>
          <a href="#" className="text-darkBlue hover:text-lightGreen">Contato</a>
        </nav>
        <Popover className="lg:hidden">
          <Popover.Button className="flex items-center text-gray-900 focus:outline-none mr-4 sm:mr-20">
            <Bars3Icon className="h-8 w-8 sm:h-10 sm:w-10" aria-hidden="true" />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 bg-white rounded-md shadow-lg mt-2 right-0 w-48">
            <div className="p-4">
              <a href="#" className="block text-darkBlue font-bold hover:text-lightGreen hover:shadow-md hover:bg-gray-100 py-2 pl-4 text-left">Início</a>
              <a href="#" className="block text-darkBlue font-bold hover:text-lightGreen hover:shadow-md hover:bg-gray-100 py-2 pl-4 text-left">Serviços</a>
              <a href="#" className="block text-darkBlue font-bold hover:text-lightGreen hover:shadow-md hover:bg-gray-100 py-2 pl-4 text-left">Projetos</a>
              <a href="#" className="block text-darkBlue font-bold hover:text-lightGreen hover:shadow-md hover:bg-gray-100 py-2 pl-4 text-left">Blog</a>
              <a href="#" className="block text-darkBlue font-bold hover:text-lightGreen hover:shadow-md hover:bg-gray-100 py-2 pl-4 text-left">Páginas</a>
              <a href="#" className="block text-darkBlue font-bold hover:text-lightGreen hover:shadow-md hover:bg-gray-100 py-2 pl-4 text-left">Contato</a>
            </div>
          </Popover.Panel>
        </Popover>
      </div>
    </header>
  );
}

export default Header;

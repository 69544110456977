import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const ImageCarousel = () => {
  return (
    <Swiper
      spaceBetween={0}
      centeredSlides={true}
      autoplay={{
        delay: 7000, // Tempo de transição de 5 segundos
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
        dynamicBullets: false,
      }}
      navigation={true}
      modules={[Autoplay, Navigation, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="relative w-full h-[600px] overflow-hidden">
          <img
            src="/imgIndustria01.jpg"
            alt="Imagem 1"
            className="w-full h-full object-cover transform scale-100 animate-slideRightZoomIn"
          />
          <div className="absolute inset-0 flex flex-col items-start justify-center p-8 md:p-16 text-white max-w-[50%]">
            <h3 className="text-3xl md:text-5xl font-bold mb-4">Manutenção Especializada Máquinas FICEP</h3>
            <p className="text-lg md:text-2xl mt-4">A FP Automação oferece manutenção especializada em sistemas FICEP, incluindo máquinas Kaltenbach, Danobat e Peddinghaus. Garantimos alta performance e longevidade para seus equipamentos industriais.</p>
            <div className="flex space-x-4 mt-8">
              <button className="px-6 py-3 bg-lightGreen text-darkBlue font-bold rounded hover:bg-white">Saiba Mais...</button>
              <button className="px-6 py-3 bg-transparent border-2 border-lightGreen text-lightGreen font-bold rounded hover:bg-lightGreen hover:text-darkBlue">Fale Conosco</button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative w-full h-[600px] overflow-hidden">
          <img
            src="/imgIndustria02.jpg"
            alt="Imagem 2"
            className="w-full h-full object-cover transform scale-100 animate-slideRightZoomIn"
          />
          <div className="absolute inset-0 flex flex-col items-start justify-center p-8 md:p-16 text-white max-w-[50%]">
            <h3 className="text-3xl md:text-5xl font-bold mb-4">Reforma e Retrofit de Máquinas</h3>
            <p className="text-lg md:text-2xl mt-4">Efetuamos reformas e retrofit de máquinas CNCs e equipamentos industriais com peças e componentes de qualidade, aumentando sua eficiência.</p>
            <div className="flex space-x-4 mt-8">
              <button className="px-6 py-3 bg-lightGreen text-darkBlue font-bold rounded hover:bg-white">Saiba Mais...</button>
              <button className="px-6 py-3 bg-transparent border-2 border-lightGreen text-lightGreen font-bold rounded hover:bg-lightGreen hover:text-darkBlue">Fale Conosco</button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative w-full h-[600px] overflow-hidden">
          <img
            src="/imgIndustria03.webp"
            alt="Imagem 3"
            className="w-full h-full object-cover transform scale-100 animate-slideRightZoomIn"
          />
          <div className="absolute inset-0 flex flex-col items-start justify-center p-8 md:p-16 text-white max-w-[50%]">
            <h3 className="text-3xl md:text-5xl font-bold mb-4">Reparo de Inversores e CLPs</h3>
            <p className="text-lg md:text-2xl mt-4">Realizamos manutenção e reparo especializado de Inversores de Frequência, CLPs, IHMs e CPUs Industriais, garantindo o máximo desempenho e confiabilidade.</p>
            <div className="flex space-x-4 mt-8">
              <button className="px-6 py-3 bg-lightGreen text-darkBlue font-bold rounded hover:bg-white">Saiba Mais...</button>
              <button className="px-6 py-3 bg-transparent border-2 border-lightGreen text-lightGreen font-bold rounded hover:bg-lightGreen hover:text-darkBlue">Fale Conosco</button>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default ImageCarousel;

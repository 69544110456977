import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ImageCarousel from './components/ImageCarousel';

function Loader() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <div className="relative">
        <img
          src="/logoFP.png"
          alt="Logo da Empresa"
          className="absolute inset-0 h-24 w-24 object-contain m-auto"
        />
        <div className="border-gray-300 h-32 w-32 animate-spin rounded-full border-8 border-t-blue-600"></div>
      </div>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    window.addEventListener('load', handleLoad);

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 segundos como medida de segurança

    return () => {
      window.removeEventListener('load', handleLoad);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <ImageCarousel />
          </main>
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
